import React, { useEffect, useState } from 'react';
import { Form  } from '@douyinfe/semi-ui'
import Framework from './Framework';
import axios from 'axios';
import { getAuthConfig } from '../utility';

function SettingPage() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('/v1/product', getAuthConfig()).then((response) => {
      setData(response.data);
    }).catch((error) => {
      console.log(error);
    });

  }, []);

  return (
    <Framework content={
      data.map(item => {
        return <Form>
          <Form.Input field='name' label='App Name' readonly initValue={item.name} style={{ width: 300 }}  />
          <Form.Input field='name' label='App ID' readonly initValue={item.name} style={{ width: 300 }}  />
          <Form.Input field='id' label='App Key' readonly initValue={item.productKey} style={{ width: 300 }} />
          <Form.Input field='id' label='App Secret' readonly initValue={item.productSecret} style={{ width: 300 }} />
        </Form>
      })

    }>
    </Framework>
  );
}


export default SettingPage;