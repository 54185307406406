import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as utility from "../utility"


function RequireAuth(props) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!utility.isLoggedIn()) {
      navigate("/login");
    }
  });

  return props.children;

}

export default RequireAuth;