import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import LoginPage from './componnets/LoginPage'
import DevicePage from './componnets/DevicePage'
import FaultPage from './componnets/FaultPage'
import ProductionPage from './componnets/ProductionPage'
import SettingPage from './componnets/SettingPage'
import EmployeePage from './componnets/EmployeePage'
import ProductPage from './componnets/ProductPage'
import reportWebVitals from './reportWebVitals';
import RequireAuth from './componnets/RequireAuth';
import axios from 'axios';
import { Toast } from '@douyinfe/semi-ui';
import { history, setJWT } from './utility';


axios.interceptors.response.use((response) => {
  return response;
}, (error) => { // Anything except 2XX goes to here
  const status = error.response?.status || 500;
  if (status === 401) {
    setJWT("");
    history.push("/login", {});
    // window.location = window.location.protocol + "//" + window.location.host + "/login"
  } else if (status === 504) {
    Toast.error("无法连接服务器，请检查网络");
  } else {
    Toast.error("查询失败: " + error.message);
  }
  return Promise.reject(error); // Delegate error to calling side
});


function App() {

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route exact path="/" element={<RequireAuth><DevicePage /></RequireAuth>} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/fault" element={<RequireAuth><FaultPage /></RequireAuth>} />
          <Route exact path="/production" element={<RequireAuth><ProductionPage /></RequireAuth>} />
          <Route exact path="/employee" element={<RequireAuth><EmployeePage /></RequireAuth>} />
          <Route exact path="/setting" element={<RequireAuth><SettingPage /></RequireAuth>} />
          <Route exact path="/product" element={<RequireAuth><ProductPage /></RequireAuth>} />
          {/* <Route exact path="/" element={<App></App>} /> */}
        </Routes>

      </div>
    </BrowserRouter>
  )
}

const root = createRoot(document.getElementById('root'));
root.render(
  <App></App>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
