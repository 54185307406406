import React, { useEffect, useState } from 'react';
import { List, Input, Descriptions, Badge, Spin, Tooltip, Avatar, Space, Divider } from '@douyinfe/semi-ui'
import { IconSearch, IconLink, IconUnlink } from '@douyinfe/semi-icons'
import Framework from './Framework';
import axios from 'axios';
import { getAuthConfig, minutesPass, relativeTime, tableStyle, timestampToTime } from '../utility';

function DeviceTable() {
  const [data, setData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState("");


  function query() {
      //请求state数据
      axios.get('/v1/states', getAuthConfig()).then((response) => {
        setLoading(false);
        let newData = sortDevice(response.data);
        setData(newData);
        doFilter(newData, keywords);
      }).catch((error) => {
        console.log(error);
      });
  }


  useEffect(() => {


    let timerId = setInterval(() => {
      query();
    }, 30000);

    setLoading(true);
    query();

    return () => {
      //unmount
      clearInterval(timerId);
    }
  }, [keywords]);

  function sortDevice(devices) {
    if (devices === null || devices === undefined) {
      return null;
    }
    return devices.sort((a, b) => {

      if (isOnline(a?.updatedAt) !== isOnline(b?.updatedAt)) {
        return isOnline(a?.updatedAt) ? -1 : 1;
      }

      let getString = (val) => {
        if (val === undefined) {
          return "";
        }
        if (val === null) {
          return "";
        }
        return val;
      };
      let getInt = (val) => {
        if (val === undefined) {
          return 0;
        }
        if (val === null) {
          return 0;
        }
        return parseInt(val);
      }
      let getAuth = (e) => {
        if (e === undefined) {
          return 0;
        }
        if (e === null) {
          return 0;
        }
        let n = e['剩余授权'];
        if (n === undefined) {
          return 0;
        }
        return parseInt(n);
      }

      let aEmployee = getString(a?.employee?.name);
      let bEmployee = getString(b?.employee?.name);
      if (aEmployee !== bEmployee) {
        return -aEmployee.localeCompare(bEmployee);
      }

      let aLeftAuth = getAuth(a?.extra);
      let bLeftAuth = getAuth(b?.extra);
      if (aLeftAuth !== bLeftAuth) {
        return aLeftAuth - bLeftAuth;
      }

      let aName = getString(a?.device?.name);
      let bName = getString(b?.device?.name);
      return aName.localeCompare(bName);
    });
  }


  const fileTextStyle = {
    fontFamily: "Consolas",
    width: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  };

  function getData(item) {
    let list = [
      { key: '设备状态', value: item.state },
      { key: '值班人员', value: item.employee === undefined ? "-" : item.employee.name },
      {
        key: '更新时间', value: item.updatedAt === undefined ? "-" :
          <Tooltip style={{ fontFamily: "Consolas" }} content={timestampToTime(item.updatedAt)}>
            {relativeTime(item.updatedAt)}
          </Tooltip>
      },
    ];
    if (item.device.type !== undefined && item.device.type !== "") {
      list.push({ key: "设备型号", value: item.device.type })
    }
    if (item.extra !== undefined) {
      for (let key in item.extra) {
        if (key === "文件") {
          let txt = item.extra[key];
          list.push({ key: key, value: <Tooltip content={txt}><div style={fileTextStyle}>{txt}</div></Tooltip> });
          continue;
        }
        list.push({ key: key, value: item.extra[key] });
      };
    }
    return list;
  }

  function isOnline(timestamp) {
    if (timestamp === undefined) {
      return false;
    }
    return minutesPass(timestamp) < 3;
  }

  const doFilter = (allData, key) => {
    let newList;
    key = key.trim();
    if (key !== "") {
      newList = allData.filter((item) => {
        if (item.device.name.indexOf(key) !== -1) {
          return true;
        }
        if ((item?.employee?.name + "").indexOf(key) !== -1) {
          return true;
        }
        let extra = item?.extra;
        if (extra !== null && extra !== undefined) {
          let f = extra['文件'];
          if (f !== undefined && f.indexOf(key) !== -1) {
            return true;
          }
        }
        return false;
      });
    } else {
      newList = allData;
    }
    setFilterData(newList);
  }

  const onSearch = (str) => {
    str = str.trim();
    setKeywords(str);
    doFilter(data, str);
  };

  const style = {
    border: '1px solid var(--semi-color-border)',
    backgroundColor: 'var(--semi-color-bg-2)',
    borderRadius: '3px',
    paddingLeft: '10px',
    margin: '8px 0px',
    // fontSize: '10px'
  };

  function getOnlineCount() {
    if (filterData == null) {
      return 0;
    }
    let count = 0;
    for (let i = 0; i < filterData.length; ++i) {
      if (isOnline(filterData[i].updatedAt)) {
        ++count;
      }
    }
    return count;
  }

  function getOfflineCount() {
    if (filterData == null) {
      return 0;
    }
    let count = 0;
    for (let i = 0; i < filterData.length; ++i) {
      if (!isOnline(filterData[i].updatedAt)) {
        ++count;
      }
    }
    return count;
  }

  const frameStyle = {
    borderRadius: '10px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '14px',
    marginTop: '10px',
    border: '1px solid var(--semi-color-border)',
  };

  return (
    <Spin spinning={loading}>
      {/* <Space style={{ width: '100%' }}> */}
        <Input onChange={(v) => onSearch(v)} placeholder='设备名、值班人员、文件' prefix={<IconSearch />} style={{ width: '100%' }} />
      {/* </Space> */}
      <div style={tableStyle}>
        <Descriptions row size='large' data={[
          { key: '在线数量', value: getOnlineCount() },
          { key: '离线数量', value: getOfflineCount() },
          { key: '总数量', value: filterData == null ? 0 : filterData.length },
        ]}></Descriptions>

        <Divider margin='10px'></Divider>
        <List
          grid={{
            gutter: 15,
            // span: 3,
            xs: 24,
            sm: 12,
            md: 12,
            lg: 6,
            xl: 4,
            xxl: 4,
            xxxl: 3,
          }}
          // style={{ flexBasis: '100%', flexShrink: 0, borderBottom: '1px solid var(--semi-color-border)' }}
          dataSource={filterData}
          renderItem={item => (
            <List.Item style={style}>
              <div>
                <h4>
                  <span style={{ fontFamily: "Consolas" }}><Badge shape='square' count={isOnline(item.updatedAt) ? "在线" : "离线"} type={isOnline(item?.updatedAt) ? "success" : "danger"} /> {item.device.name}</span>
                </h4>
                <Descriptions
                  align="center"
                  size="small"
                  data={getData(item)}
                />
              </div>
            </List.Item>
          )}
        />

      </div>
    </Spin>
  );
}

function DevicePage() {

  return (
    <Framework content={
      <DeviceTable></DeviceTable>
    }>
    </Framework>
  );
}


export default DevicePage;